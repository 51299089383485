import React, { useEffect } from "react";
import NavBar from "../components/navBar";
import { useNavigate } from "react-router-dom";
import HeroFeatures from "../components/heroFeatures";
import Footer from "../components/footer";
import "../index.css";

export default function Home() {
  const navigate = useNavigate();

  useEffect(() => {
    // Function to check for the auth cookie
    const checkAuthCookie = () => {
      const cookieName = "authToken"; // Replace with your actual cookie name
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(`${cookieName}=`)) {
          return true;
        }
      }
      return false;
    };

    if (checkAuthCookie()) {
      window.location.href = "https://app.inparallel.app";
    }
  }, []);

  return (
    <>
      <div className="mainWrapper">
        <NavBar lightTheme={false} />
        <div className="sidePadding">
          <div className="heroWrapper">
            <h1>Accountability is now as easy as starting your to-do list.</h1>
            <span className="joinBetaHeadline">Join the beta:</span>
            <iframe
              width="100%"
              height="320"
              src="https://e14e7c17.sibforms.com/serve/MUIFAPPRdY-xJ5ANQHPEQIBrPtqF5__ucmY7IRTpKzuQy-bDNigSmZoaKpf2uoao2hIzeF2i3b_QU3qgHQBrFuA7m_91QlNmA5TuxlnH1ILOdY9AyNaIAODvyWu4_H52OwbATXHrn9D4RmYLQVMuWn-vXL22AXj5Xq377ivrBBXiEQY2nquMANLsASBcRYYbby22vEGLNg54ISwX"
              frameBorder="0"
              scrolling="no"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                marginLeft: "auto",
                marginRight: "auto",
                maxWidth: "100%",
                marginBottom: "100px",
              }}
            ></iframe>
            {/* <form>
              <div className="inputWrapper">
                <input
                  type="email"
                  placeholder="Your email"
                  className="emailInput"
                  required
                />
                <button type="submit" className="submit">
                  Join the Waitlist
                </button>
              </div>
            </form> */}
          </div>
        </div>
        <div className="lowerWrapper">
          <HeroFeatures />
          <div className="bottomSeparator" />
          <Footer lightTheme={false} />
        </div>
      </div>
    </>
  );
}
