import React, { useEffect } from "react";
import NavBar from "../components/navBar";
import Footer from "../components/footer";

export default function TermsOfService() {
  useEffect(() => {
    const scriptId = "termly-jssdk";
    const existingScript = document.getElementById(scriptId);

    if (existingScript) {
      existingScript.parentNode.removeChild(existingScript);
    }

    const script = document.createElement("script");
    script.id = scriptId;
    script.src = "https://app.termly.io/embed-policy.min.js";
    document.body.appendChild(script);

    // Change body background color
    const previousBackgroundColor = document.body.style.backgroundColor;
    document.body.style.backgroundColor = "white";

    // Cleanup function to reset body background color when the component unmounts
    return () => {
      document.body.style.backgroundColor = previousBackgroundColor;
      if (document.getElementById(scriptId)) {
        document.getElementById(scriptId).remove();
      }
    };
  }, []);

  return (
    <>
      <NavBar lightTheme={true} />
      <div className="lightPageWrapper">
        <div
          name="termly-embed"
          data-id="1526b62b-b4ec-40d8-8fd1-34433b11c026"
        ></div>
      </div>
      <Footer lightTheme={true} />
    </>
  );
}
