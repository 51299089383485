import React from "react";
import "../styles/heroFeatures.css";

export default function HeroFeatures() {
  return (
    <>
      <div className="features">
        <div className="feature">
          <img
            src="/images/step1.svg"
            alt="Feature"
            className="stepIcon stepOne"
          />
          <h2>List any goal</h2>
          <p>
            Write your own private checklist of goals each day - from{" "}
            <b>
              <span className="whiteText">work</span>
            </b>{" "}
            to{" "}
            <b>
              <span className="whiteText">working out</span>
            </b>{" "}
            - like you would in any other note-taking or task-tracking app.
          </p>
        </div>
        <div className="feature">
          <img src="/images/step2.svg" alt="Feature" className="stepIcon" />
          <h2>Commit to goals</h2>
          <p>
            For the goals you need extra accountability on you can share them to
            your close friends and followers, and they’ll see your goals on
            their feed.
          </p>
        </div>
        <div className="feature">
          <img
            src="/images/step3-heart.svg"
            alt="Feature"
            className="stepIcon"
          />
          <h2>Like & comment</h2>
          <p>
            Public goals make friends. People can like and comment on your
            shared goals with ideas, support, or commit to them along with you.
          </p>
        </div>
        <div className="feature">
          <img src="/images/step4.svg" alt="Feature" className="stepIcon" />
          <h2>Join others' goals</h2>
          <p>
            It’s more fun to do things with friends. From{" "}
            <b>
              <span className="whiteText">run 5 miles</span>
            </b>{" "}
            to
            <b>
              <span className="whiteText"> finish research paper</span>
            </b>
            , you never know what new, exciting, motivating energy awaits.
          </p>
        </div>
      </div>
    </>
  );
}
