// import React from "react";
// import "../index.css";
// import { Link } from "react-router-dom";

// export default function Footer({ lightTheme }) {
//   return (
//     <>
//       <div className="footerWrapper">
//         <div className="footerPages">
//           <Link to="/privacy" className="footerPage">
//             Privacy
//           </Link>
//           <Link to="/terms" className="footerPage">
//             Terms
//           </Link>
//         </div>
//         <div className="copyright">
//           <span>© 2024 Parallel Productivity, All rights reserved.</span>
//         </div>
//         <div className="socials">
//           <a
//             href="https://www.linkedin.com/company/inparallelapp"
//             className="social"
//           >
//             <img src="/images/LinkedIn.svg" className="socialIcon" />
//           </a>
//           <a href="https://www.instagram.com/inparallelapp/" className="social">
//             <img src="/images/Instagram.svg" className="socialIcon" />
//           </a>
//           <a href="https://www.facebook.com/inparallelapp/" className="social">
//             <img src="/images/Facebook.svg" className="socialIcon" />
//           </a>
//         </div>
//       </div>
//     </>
//   );
// }

import React from "react";
import "../index.css";
import { Link } from "react-router-dom";

export default function Footer({ lightTheme }) {
  const linkedinSrc = lightTheme
    ? "/images/linkedin-light.png"
    : "/images/LinkedIn.svg";
  const instagramSrc = lightTheme
    ? "/images/instagram-light.svg"
    : "/images/Instagram.svg";
  const facebookSrc = lightTheme
    ? "/images/facebook-light.svg"
    : "/images/Facebook.svg";
  const footerPageClass = lightTheme ? "footerPageLight" : "footerPage";
  const footerWrapperClass = lightTheme ? "footerWrapper" : "footerWrapper";
  const copyrightClass = lightTheme ? "copyrightLight" : "copyright";
  const socialClass = lightTheme ? "social" : "social";
  const socialIconClass = lightTheme ? "socialIcon" : "socialIcon";

  return (
    <>
      <div className={footerWrapperClass}>
        <div className="footerPages">
          <Link to="/privacy" className={footerPageClass}>
            Privacy
          </Link>
          <Link to="/terms" className={footerPageClass}>
            Terms
          </Link>
          <a href="mailto:support@inparallel.app" className={footerPageClass}>
            Support
          </a>
        </div>
        <div className={copyrightClass}>
          <span>© 2024 Parallel Productivity, All rights reserved.</span>
        </div>
        <div className="socials">
          <a
            href="https://www.linkedin.com/company/inparallelapp"
            className={socialClass}
          >
            <img src={linkedinSrc} className={socialIconClass} />
          </a>
          <a
            href="https://www.instagram.com/inparallelapp/"
            className={socialClass}
          >
            <img src={instagramSrc} className={socialIconClass} />
          </a>
          <a
            href="https://www.facebook.com/inparallelapp/"
            className={socialClass}
          >
            <img src={facebookSrc} className={socialIconClass} />
          </a>
        </div>
      </div>
    </>
  );
}
