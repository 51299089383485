import React from "react";
import "../styles/navbar.css";
import { Link } from "react-router-dom";

export default function NavBar({ lightTheme }) {
  const logoSrc = lightTheme
    ? "/images/parallel-logo.png"
    : "/images/parallel-logo-white.svg";
  const linkClassName = lightTheme
    ? "loginLinkHoverColorLight"
    : "loginLinkHoverColor";
  const loginClassName = lightTheme ? "login" : "login";

  return (
    <>
      <div className="wrapper">
        <Link to="/">
          <img src={logoSrc} className="navLogo" alt="Parallel Logo" />
        </Link>
        <div className="allNav">
          <div className="navButtons">
            <a
              href="https://app.inparallel.app/signin"
              className={linkClassName}
            >
              <div className={loginClassName}>Log in</div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

NavBar.defaultProps = {
  lightTheme: true,
};
